:root {
  --colorPrimary: #FF7A00;
  --colorGreenDark: #314D3A;
  --colorPurple: #53385b;
  --colorRed: rgb(221, 6, 6);

  --colorWhite: #ffffff;
  --colorBlack: #000000;
  --colorGrayAlpha: rgba(0, 0, 0, 0.5);

  --colorGray: rgb(245, 245, 245);
  --colorGrayDark: #dfdfdf;
}

input {
  border-radius: 6px;
  width: 100%;
  border-color: rgba(26,26,26, .2);
  border-width: 0.5px;
  font-size: 16px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0,0,0,.07), 0 1px 1.5px 0 rgba(0,0,0,.05);
  height: 36px;
  padding: 0px 16px;
}

textarea {
  border-radius: 6px;
  width: 100%;
  border-color: rgba(26,26,26, .2);
  border-width: 0.5px;
  font-size: 16px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0,0,0,.07), 0 1px 1.5px 0 rgba(0,0,0,.05);
  padding: 16px 16px;
  resize: none;
}